import {FunctionComponent, useEffect, useState} from "react";
import {IPage} from "../../components/PageWrapper/types";
import {DeviceVerificationPageContainer, Link, Phrase, SubTitle} from "./DeviceVerificationPage.style";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import I18n from "../../components/I18n/I18n";
import TextField from "../../components/Fields/TextField/TextField";
import {asI18nKey} from "../../common/utilities/Localization";
import useIdentityForm from "../../common/hooks/UseIdentityForm";
import TimedResendButton from "../../components/TimedResendButton/TimedResendButton";
import useTrigger from "../../common/hooks/UseTrigger";
import useAPI from "../../common/hooks/UseAPI";
import {sendCodeNt, verifyCodeNt} from "./DeviceVerificationService";
import useIdentityNavigation from "../../common/hooks/UseIdentityNavigation";
import {ServerError} from "../../common/utilities/API/types";
import {AlertType} from "../../components/AlertToast/types";
import useIdentityParams from "../../common/hooks/Parameters/UseIdentityParams";
import usePageAccessGuard from "../../common/hooks/UsePageAccessGuard";
import SupportModal from '../../components/SupportModal/SupportModal';
import LinkParagraph from "../../components/LinkParagraph/LinkParagraph";

const VerifyCodePage: FunctionComponent<IPage> = (
    { trackingCategory, translate, onServerAlert, onUnexpectedError, disablePage }
) => {
    const { allParams, routerParams } = useIdentityParams<{
        maskedphonenumber : string;
        email: string;
        token: string;
    }>();

    usePageAccessGuard(!routerParams.maskedphonenumber);
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const cancelResendCooldown = useTrigger();
    const api = useAPI();
    const { redirectToReturnUrl, navigate } = useIdentityNavigation();
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);

    useEffect(() => {
        resendCode(allParams).then();
    }, []);

    const resendCode = async ({ email, token }: typeof allParams): Promise<void> => {
        await api(() => sendCodeNt(email, token), {
            errorsMap: data => [
                {
                    error: ServerError.CodeIssueFailure,
                    message: translate(asI18nKey('device_verification.error.failed_code_generate'))
                },
                {
                    error: ServerError.UserLocked,
                    message: translate(asI18nKey('device_verification.error.update_phone_limit_lock'))
                },
                {
                    error: ServerError.TwoFactorWithSmsFailedFallbackToEmail,
                    message: translate(asI18nKey('device_verification.error.fallback_to_email_two_factor'), { fallbackEmail: data?.fallbackEmail })
                }
            ],
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (message === ServerError.IncorrectCredentials) {
                    logSubmissionFailure(message);
                    return navigate('Login');
                }
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else onUnexpectedError(forceRefresh);

                cancelResendCooldown.fire();
            }
        });
    }
    const onSubmit = async ({ code }: Record<string, string>): Promise<boolean> => {
        return await api(() => verifyCodeNt(code, allParams.email, allParams.token), {
            errorsMap: data => [
                {
                    error: ServerError.InvalidToken,
                    message: translate(asI18nKey('device_verification.error.invalid_token'))
                },
                {
                    error: ServerError.CodeVerificationRejected,
                    message: translate(asI18nKey('device_verification.error.rejected_code'), data)
                },
                {
                    error: ServerError.UserLocked,
                    message: translate(asI18nKey('device_verification.error.user_locked'), { minutes: data?.minutesLeft || 5 })
                }
            ],
            onSuccess: async () => {
                disablePage();
                await redirectToReturnUrl();
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else onUnexpectedError(forceRefresh);
            }
        });
    }

    return <DeviceVerificationPageContainer>
        <SubTitle>
            <I18n params={{ phone_number: allParams.maskedphonenumber}}>device_verification.verify.sub_title</I18n>
        </SubTitle>

        <TextField
            name={'code'}
            automationName={'code'}
            type={'Text'}
            label={translate(asI18nKey('device_verification.verify.label_text'))}
            focusOnPageLoad
            required
            verificationConfig={{
                pattern: [ /^\d{6}$/ ],
                customError: translate(asI18nKey('device_verification.error.invalid_code'))
            }}
            placeholder={'000-000'}
            shouldVerify={inputsVerificationTrigger.active}
            trackingCategory={trackingCategory}
            onChange={setInput}
            onEnterPress={submissionTrigger.fire}
        />
        <SubmitButton
            automationName={'submit-button'}
            onSuccess={onSubmit}
            trackingCategory={trackingCategory}
            formInputs={formInputs}
            onFailedVerification={inputsVerificationTrigger.fire}
            shouldSubmit={submissionTrigger.active}
            trackingComponentName={'verify code'}
        >
            <I18n>device_verification.verify.button_text</I18n>
        </SubmitButton>
        <TimedResendButton
            onSendCode={() => resendCode(allParams)}
            confirmationMessage={translate(asI18nKey('device_verification.no_code.confirmation'))}
            buttonLabel={translate(asI18nKey(`device_verification.no_code.action`))}
            buttonPrefix={translate(asI18nKey('device_verification.no_code'))}
            timerPrefix={translate(asI18nKey('device_verification.no_code.cooldown.prefix'))}
            timerSuffix={translate(asI18nKey('device_verification.no_code.cooldown.suffix'))}
            cancelCooldown={cancelResendCooldown.active}
            cooldownTime={30}
        />
        <LinkParagraph
            prefix={translate(asI18nKey('device_verification.general.help_text'))}
            linkLabel={translate(asI18nKey('device_verification.general.help_link_text'))}
            callback={() => setSupportModalOpen(true)}
        />
        <SupportModal
            isOpen={supportModalOpen}
            onClose={() => setSupportModalOpen(false)}
        />
    </DeviceVerificationPageContainer>
}

export default VerifyCodePage;
